* {
    padding: 0;
    margin: 0;
}
body {
    background-color: pink;
}
#frame {
    position: relative;
    width: 400px;
    height: 300px;
    margin: 250px auto;
}
.left, .right {
    top: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
.left {
    left: 35px;
}
.right {
    right: 35px;
    z-index: -1;
}
.bottom {
    bottom: 36px;
    left: 100px;
    width: 200px;
    height: 200px;
    transform: rotate(45deg);
    z-index: -1;
}
.heart {
    position: absolute;
    box-shadow: 0 0 40px #d5093c;
    animation: beat .4s ease infinite normal;
    background: linear-gradient(-90deg, #F50A45 0%, #d5093c 40%);
}
@keyframes beat {
    0% {
        transform: scale(1) rotate(225deg);
        box-shadow: 0 0 40px #d5093c;
    }
    50% {
        transform: scale(1.1) rotate(225deg);
        box-shadow: 0 0 70px #d5093c;
    }
    100% {
        transform: scale(1) rotate(225deg);
        box-shadow: 0 0 40px #d5093c;
    }
}
.word {
    position: absolute;
    width: 50%;
    top: 75%;
    left: 25%;
    text-align: center;
    transform: translateY(-50%);
    font-family: 'Love Ya Like A Sister', cursive;
    font-size: 40px;
    color: #c70012;
    padding: 0 20px;
}